import React from "react"
import SEO from "../components/seo"
import firebase from "gatsby-plugin-firebase"
import DefaultLayout from "../layouts/defaultLayout"

class Feedback extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      feedback: "",
    }

    this.updateField = this.updateField.bind(this)
  }

  submitFeedback = e => {
    e.preventDefault()
    if (
      this.state.name !== "" &&
      this.state.name !== " " &&
      this.state.email !== "" &&
      this.state.email !== " " &&
      this.state.feedback !== "" &&
      this.state.name !== " "
    ) {
      firebase
        .firestore()
        .collection("feedback")
        .doc()
        .set({
          name: this.state.name,
          email: this.state.email,
          feedback: this.state.feedback,
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        })
        .then(
          console.log(
            "Document written to Firestore with ID: ",
            this.state.email
          )
        )
        .catch(function(error) {
          alert("ERROR. Please email team@youchangeearth.org directly.")
          console.error("Error adding document to Firestore: ", error)
        })

      firebase
        .database()
        .ref("feedback/")
        .push({
          name: this.state.name,
          email: this.state.email,
          feedback: this.state.feedback,
          createdAt: firebase.database.ServerValue.TIMESTAMP,
        })
        .then(
          console.log(
            "Document written to Database with ID: ",
            this.state.name,
            this.state.email,
            this.state.feedback
          )
        )
        .catch(function(error) {
          alert("ERROR. Please email team@youchangeearth.org directly.")
          console.error("Error adding to database: ", error)
        })

      this.setState({
        name: "",
        email: "",
        feedback: "",
      })

      alert(
        "Feedback sent successfully! Please wait 24-72 hours for a response or email team@youchangeearth.org directly."
      )
    } else {
      alert("Please fill out all fields before submitting.")
    }
  }

  updateField = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  updateField = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  updateField = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  render() {
    if (this.props.location.state == null) {
      return (
        <DefaultLayout>
          <SEO title="Feedback Form" />
          <div className="feedback">
            <div>
              <h1>Feedback Form</h1>
              <form onSubmit={this.submitFeedback}>
                <input
                  className="nameFeed"
                  onChange={this.updateField}
                  type="text"
                  aria-label="Name input"
                  name="name"
                  placeholder="Name"
                  value={this.state.name}
                />
                <br></br>
                <br></br>
                <input
                  className="emailFeed"
                  onChange={this.updateField}
                  type="text"
                  aria-label="Email input"
                  name="email"
                  placeholder="Email"
                  value={this.state.email}
                />
                <br></br>
                <br></br>
                <textarea
                  className="feedbackInput"
                  onChange={this.updateField}
                  type="text"
                  name="feedback"
                  placeholder="Message"
                  aria-label="Feedback input textarea"
                  value={this.state.feedback}
                />

                {/* <div style={{ color: "red" }}>{this.state.emailError}</div> */}

                <br />
                <br />
                <br />
                <button type="submit" className="button journey">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </DefaultLayout>
      )
    }
  }
}

export default Feedback
